/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cloud_logic_custom": [
        {
            "name": "gtfsRestApi",
            "endpoint": "https://mgaiqs4dif.execute-api.eu-west-1.amazonaws.com/qa",
            "region": "eu-west-1"
        }
    ],
    "aws_cognito_identity_pool_id": "eu-west-1:7bd1c54f-78f5-4580-94a4-df99a8b0f188",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_rGIJtaXXd",
    "aws_user_pools_web_client_id": "5it0kcb0ct3h2i9n5nk64r4stb",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_dynamodb_all_tables_region": "eu-west-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "GTFS_CONTRACTS-qa",
            "region": "eu-west-1"
        },
        {
            "tableName": "GTFS_EMPRESA-qa",
            "region": "eu-west-1"
        },
        {
            "tableName": "GTFS_FUSION-qa",
            "region": "eu-west-1"
        },
        {
            "tableName": "GTFS_LINES-qa",
            "region": "eu-west-1"
        }
    ]
};


export default awsmobile;
